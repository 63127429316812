<template>
  <section class="m-dxby-SUniform" :class="classNames">
    <ul>
      <li
        v-for="(item, key) in mainUniformData"
        :key="key"
      >
        <router-link :to="{path: '/productDetails', query: {id: item.id}}">
          <van-image
            round
            :alt="item.name"
            fit="cover"
            class="m-dxby-SUniform-img"
            lazy-load
            :src="item.mainImg"
          />
          <span>{{item.sn}}</span>
          <span>{{item.name}}</span>
        </router-link>
      </li>
    </ul>
  </section>
</template>

<script>
import { Image as VanImage } from 'vant'

export default {
  name: 'MainProductionList',
  props: {
    mainUniformData: {
      type: Array
    },
    classNames: {
      type: String
    }
  },
  components: {
    VanImage
  }
}
</script>

<style scoped lang="less">
@import '~@/assets/css/common.less';
.m-dxby-SUniform {
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      .w(33%);
      overflow: hidden;
      margin-bottom: 26px;
      a {
        color: #333;
        display: flex;
        flex-direction: column;
        align-items: center;
        .m-dxby-SUniform-img {
          .wh(105px, 105px);
        }
        span {
          display: block;
          .fsize(12px);
          text-align: center;
        }
        span:nth-child(2) {
          margin: 15px 0 5px;
        }
        span:nth-child(3) {
          .otx(2)
        }
      }
    }
  }
}
.m-dxby-sweater {
  ul {
    li {
      a {
        .m-dxby-SUniform-img {
          .rds(12px);
          overflow: hidden;
        }
      }
    }
  }
}
</style>
