<template>
  <div class="title">
    <span v-html="upTitle"></span>
    <h3>
      <i>{{vtitle}}</i>
    </h3>
    <span v-html="downTitle"></span>
  </div>
</template>

<script>
export default {
  name: 'titles',
  props: {
    upTitle: {
      type: String,
      require: true
    },
    vtitle: {
      type: String,
      require: true
    },
    downTitle: {
      type: String,
      require: true
    }
  }
}
</script>

<style scoped lang="less">
.title {
  span, h3 {
    color: #015462;
  }
  span:first-child,
  span:nth-child(3) {
    font-family: '方正粗黑宋简体';
    font-size: 14px;
    display: block;
    text-align: center;
    letter-spacing: -1px;
  }
  span:nth-child(3) {
    font-family: '微软雅黑';
  }
  h3 {
    font-family: '方正粗黑宋简体';
    font-size: 30px;
    display: block;
    text-align: center;
    position: relative;
    padding-top: 4px;
    i {
      padding: 0 10px;
      background-color: #fff;
      position: relative;
      z-index: 2;
    }
    &::before {
      width: 100%;
      height: 1px;
      content: '';
      background-color: #015462;
      position: absolute;
      top: 21px;
      left: 0;
    }
  }
}
</style>
