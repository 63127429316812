<template>
  <div class="container">
    <section class="container-con">
      <!-- Header S -->
      <Head/>
      <!-- Header E -->
      <section :class="addFixeds == true ? 'addFixed' : ''">
        <!-- Nav S -->
        <Nav/>
        <!-- Nav E -->
      </section>
      <!-- Banner S -->
      <Swipe
        :autoplay="3000"
        class="swiper-containers"
      >
        <SwipeItem
          v-for="(banner, index) in bannerData"
          :key="index"
          @click="() => $router.push({path: banner.pageUrl})"
          class="m-dxby-swipeItem"
        >
          <img
            :src="banner.imgUrl"
            class="m-dxby-bannerImg"
          >
        </SwipeItem>
      </Swipe>
      <!-- Banner E -->
      <div class="m-dxby-main">
        <!-- 线下门店 S -->
        <section class="m-dxby-store">
          <videoPlayer
            :playsinline="true"
            :options="playerOptions"
            webkit-playsinline="true"
            x-webkit-airplay="true"
            class="video-player vjs-custom-skin"
          />
        </section>
        <!-- 线下门店 E -->
        <!-- 店铺主营 S -->
        <section class="mainBussiness">
          <van-image
            width="100%"
            :src="mainBussiness"
          />
        </section>
        <!-- 店铺主营 E -->
        <!-- 织染工厂 S -->
        <section style="margin: 36px 0 30px;">
          <vTitle
            :upTitle="'<i>WEAVING &nbsp; AND &nbsp; DYEING &nbsp; FACTORY</i>'"
            :vtitle="'织染工厂'"
            :downTitle="'<i>但凡匠品 &nbsp; 皆有源头</i>'"
          />
        </section>
        <section class="m-dxby-factory-wrap">
          <vSwiper
            :options="swiperOption"
            ref="mySwiper"
            class="m-dxby-swiper-wrap"
          >
            <swiper-slide
              class="m-dxby-swiper-wrap-item"
              v-for="(slider, key) in factoryPhoto"
              :key="key"
            >
              <div class="m-dxby-factory" @click="seeImagePreviewFn(key)">
                <div class="m-dxby-factory-img">
                  <img
                    :src="slider.imgUrl"
                    :alt="slider.alt"
                  >
                </div>
                <span>{{slider.text}}</span>
              </div>
            </swiper-slide>
            <div class="m-dxby-swiper-pagination" slot="pagination"></div>
          </vSwiper>
        </section>
        <!-- 织染工厂 E -->
        <!-- 仓库物流 S -->
        <section style="margin: 36px 0 30px;">
          <vTitle
            :upTitle="'<i>WAREHOUSE &nbsp; LOGISTICS</i>'"
            :vtitle="'仓库物流'"
            :downTitle="'<i>智能大型仓储基地和强大的物流配送团队</i>'"
          />
        </section>
        <section class="m-dxby-ckwl">
          <van-image
            width="100%"
            src="images/index/ckwl.jpg"
          />
        </section>
        <!-- 仓库物流 E -->
        <!-- 品控中心 S -->
        <section style="margin: 36px 0 30px;">
          <vTitle
            :upTitle="'<i>SOURCE FACTORY &nbsp; STRENGTH &nbsp; QUALTY</i>'"
            :vtitle="'品控中心'"
            :downTitle="'<i>经4轮检查管控，从源头到终端高标准把控产品品值</i>'"
          />
        </section>
        <section class="m-dxby-pk">
          <van-image
            width="100%"
            height="100%"
            :src="pkImg"
          />
        </section>
        <!-- 品控中心 E -->
        <!-- 高品质面料 S -->
        <section class="m-dxby-quality">
          <h3>THE HIGH QUALITY</h3>
          <h5>严选原材料和供应商，把控源头，打造高品质面料</h5>
        </section>
        <section class="m-dxby-quality">
          <van-image
            width="100%"
            :src="qualityImg"
          />
        </section>
        <!-- 高品质面料 E -->
        <!-- 镇店爆品 S -->
        <section class="m-dxby-bp">
          <section style="margin: 36px 0 30px;">
            <pTitle
              :downTitle="'<i>EXPLOSION &nbsp; TOWN SHOP</i>'"
              :vtitle="'镇店爆品'"
            />
          </section>
          <section class="m-dxby-bp-list">
            <ul>
              <li
                v-for="(item, key) in explosiveData"
                :key="key"
              >
                <div class="m-dxby-bp-list-img">
                  <van-image
                    fit="cover"
                    width="100%"
                    height="100%"
                    :src="item.mainImg"
                    :alt="item.name"
                  />
                </div>
                <h3>{{item.name}}</h3>
                <span>广受客户朋友的追捧，贯穿全年销售，排行前列。</span>
                <div
                  class="m-dxby-bp-list-btn"
                  @click="() => $router.push({path: '/productDetails', query: {id: item.id}})"
                >
                  <button>查看</button>
                </div>
              </li>
            </ul>
          </section>
        </section>
        <!-- 镇店爆品 E -->
        <!-- 甄选新疆精棉 S -->
        <section class="m-dxby-quality">
          <h3>甄选新疆精棉</h3>
          <h5>从源头到终端高标准把控产品品质，所以质量足够好！</h5>
        </section>
        <section class="m-dxby-quality">
          <van-image
            width="100%"
            :src="qualityImg2"
          />
        </section>
        <!-- 甄选新疆精棉 E -->
        <!-- 主推系列 S -->
        <section class="m-dxby-quality">
          <h3>主推系列</h3>
          <h5>DAXIN TEXTILE</h5>
        </section>
        <!-- 主推系列 E -->
        <!-- 校服 S -->
        <section style="margin: 36px 0 30px;">
          <pTitle
            :downTitle="'<i>SCHOOL &nbsp; UNIFORM &nbsp; 31888 &nbsp; STANDARD</i>'"
            :vtitle="'校服31888标准'"
          />
        </section>
        <Swipe
          :autoplay="2500"
          class="swiper-containers"
        >
          <SwipeItem>
            <MainPrdList :mainUniformData="frontSchoolUniformData" />
          </SwipeItem>
          <SwipeItem>
            <MainPrdList :mainUniformData="behindSchoolUniformData" />
          </SwipeItem>
        </Swipe>
        <!-- 校服 E -->
        <!-- 卫衣系列 S -->
        <section style="margin: 36px 0 30px;">
          <pTitle
            :downTitle="'<i>FLECEE &nbsp; SERIES</i>'"
            :vtitle="'卫衣系列'"
          />
        </section>
        <Swipe
          :autoplay="3000"
          class="swiper-containers"
        >
          <SwipeItem>
            <MainPrdList
              :mainUniformData="frontSweaterData"
              :classNames="'m-dxby-sweater'"
            />
          </SwipeItem>
          <SwipeItem>
            <MainPrdList
              :mainUniformData="behindSweaterData"
              :classNames="'m-dxby-sweater'"
            />
          </SwipeItem>
        </Swipe>
        <!-- 卫衣系列 E -->
        <!-- 打底保暖系列 S -->
        <section style="margin: 36px 0 30px;">
          <pTitle
            :downTitle="'<i>BOTTOM &nbsp; WARM &nbsp; SERIES</i>'"
            :vtitle="'打底保暖系列'"
          />
        </section>
        <section class="m-dxby-warmth">
          <ul>
            <li
              v-for="(item, key) in warmthData"
              :key="key"
            >
              <router-link :to="{path: '/productDetails', query: {id: item.id}}">
                <div class="m-dxby-warmth-img-box">
                  <van-image
                    :alt="item.name"
                    fit="cover"
                    class="m-dxby-SUniform-img"
                    :src="item.mainImg"
                  />
                </div>
                <span>{{item.name}}</span>
                <span>{{item.sn}}</span>
              </router-link>
            </li>
          </ul>
        </section>
        <!-- 打底保暖系列 E -->
      </div>
    </section>
    <!-- 返回顶部 S -->
    <BackTop ref="isBackTop"/>
    <!-- 返回顶部 E -->
    <Foot/>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import Head from '@/components/Head'
import Nav from '@/components/Nav'
import Foot from '@/components/Foot'
import vTitle from '@/components/Title'
import pTitle from '@/components/PTitle'
import metaLable from '@/utils/metaLable'
import BackTop from '@/components/BackTop2'
import MainPrdList from '@/components/MainProductionList'
import { hostApi } from '@/utils/requestApi'
import { videoPlayer } from 'vue-video-player'
import { bannerData, factoryPhoto } from '@/mock'
import { OtherCommonFn } from '@/assets/js/common'
import { swiper as vSwiper, swiperSlide } from 'vue-awesome-swiper'
import { Swipe, SwipeItem, Lazyload, Image as VanImage } from 'vant'
import { SWIPER_OPTION as swiperOption, PLAYER_OPTIONS as playerOptions } from '@/utils/constantManage'
import { QUALITYIMG, QUALITYIMG2, MAINSBUSINESS, COMPANY_VDIDEO, INDEX_VEDIO_ALBUM, PK_IMG } from '@/utils/imageManage'

Vue.use(Lazyload)

export default {
  name: 'home',
  metaInfo: {
    title: '广东鸿仁大新纺织品有限公司',
    meta: metaLable
  },
  data () {
    return {
      addFixeds: false,
      bannerData,
      factoryPhoto,
      factoryPhotoTotal: [],
      mainBussiness: MAINSBUSINESS,
      pkImg: PK_IMG,
      swiperOption,
      qualityImg: QUALITYIMG,
      qualityImg2: QUALITYIMG2,
      playerOptions: playerOptions(COMPANY_VDIDEO, INDEX_VEDIO_ALBUM),
      explosiveData: [],
      frontSchoolUniformData: [],
      behindSchoolUniformData: [],
      frontSweaterData: [],
      behindSweaterData: [],
      warmthData: []
    }
  },
  components: {
    Head,
    Nav,
    Foot,
    vTitle,
    pTitle,
    Swipe,
    SwipeItem,
    vSwiper,
    swiperSlide,
    videoPlayer,
    VanImage,
    BackTop,
    MainPrdList
  },
  mounted () {
    this.onscrollFn()
    this.getExplosiveData()
    this.getSchoolUniformData()
    this.getSweaterData()
    this.getwarmthData()
    this.getFactoryPhotoTotal()
  },
  methods: {
    // 浏览器滚动时元素距离顶部多少是显示和隐藏
    onscrollFn () {
      document.addEventListener('scroll', () => {
        let _topDistance = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
        // 导航菜单吸顶
        _topDistance > 400 ? this.addFixeds = true : this.addFixeds = false
        _topDistance || document.body.scrollTop > 200 ? this.$refs.isBackTop.changeBackTopIsStatusFn(true) : this.$refs.isBackTop.changeBackTopIsStatusFn(false)
      })
    },
    // 工厂相册预览
    seeImagePreviewFn (index) {
      new OtherCommonFn().isImagePreviewFn(this.factoryPhotoTotal, 0, index)
    },
    // 工厂相册预览 - 相片
    getFactoryPhotoTotal () {
      this.factoryPhoto.forEach(item => {
        this.factoryPhotoTotal.push(item.imgUrl)
      })
    },
    // 获取到镇店爆品的列表数据
    async getExplosiveData () {
      const _data = {
        id: '1356905228691050498',
        pageNum: 1,
        pageSize: 12
      }
      const res = await axios.post(`${hostApi}/goods/spu/list/cls`, _data)
      const { list } = res.data
      this.explosiveData = list
    },
    // 获取到校服列表数据
    async getSchoolUniformData () {
      const _data = {
        id: '1398534162522095617',
        pageNum: 1,
        pageSize: 12
      }
      const res = await axios.post(`${hostApi}/goods/spu/list/cls`, _data)
      const { list } = res.data
      let newSchoolUniformData = new OtherCommonFn().deepClone(list)
      // 前部分数据
      this.frontSchoolUniformData = newSchoolUniformData.slice(0, 6)
      // 后部分数据
      this.behindSchoolUniformData = newSchoolUniformData.slice(6, newSchoolUniformData.length + 1)
    },
    // 获取到卫衣列表数据
    async getSweaterData () {
      const _data = {
        id: '1349999134081105922',
        pageNum: 1,
        pageSize: 12
      }
      const res = await axios.post(`${hostApi}/goods/spu/list/cls`, _data)
      const { list } = res.data
      // ...
      let newSchoolUniformData = new OtherCommonFn().deepClone(list)
      // 前部分数据
      this.frontSweaterData = newSchoolUniformData.slice(0, 6)
      // 后部分数据
      this.behindSweaterData = newSchoolUniformData.slice(6, newSchoolUniformData.length + 1)
    },
    // 打底保暖
    async getwarmthData () {
      const _data = {
        id: '1356848646913527809',
        pageNum: 1,
        pageSize: 12
      }
      const res = await axios.post(`${hostApi}/goods/spu/list/cls`, _data)
      const { list } = res.data
      this.warmthData = list
    }
  }
}
</script>

<style scoped lang="less">
@import '~@/assets/css/common.less';
.m-dxby-main {
  margin: 0 10px;
}
.addFixed {
  .w(100%);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}
// swiper
.swiper-containers {
  // .wh(100%, 220px);
  .w(100%);
  .m-dxby-swipeItem {
    .m-dxby-bannerImg {
      .w(100%);
    }
  }
}
// 线下门店
.m-dxby-store {
  .w(100%);
  max-height: 250px;
  .bg(#015462);
  color: #fff;
  margin-top: 17px;
  position: relative;
  overflow: hidden;
  /deep/.vjs-poster {
    background-repeat:no-repeat;
    background-size: 100%;
  }
  /deep/.vjs-custom-skin > .video-js .vjs-big-play-button {
    font-size: 2em;
  }
}
// 织染工厂
/deep/.m-dxby-factory-wrap {
  width: 100%;
  overflow: visible;
  .m-dxby-swiper-wrap {
    position: relative;
    padding: 10px 0;
    .m-dxby-swiper-wrap-item {
      .w(100%);
      box-shadow: 0 0 8px #000;
      .m-dxby-factory {
        .m-dxby-factory-img {
          .wh(100%, 150px);
          img {
            .wh(100%, 100%);
          }
        }
        span {
          color: #fff;
          text-align: center;
          height: 30px;
          .lht(30px);
          .fsize(14px);
          .otx (1);
          display: block;
          color: #333;
          font-family: '微软雅黑';
        }
      }
    }
    .m-dxby-swiper-pagination {
      position: absolute;
      bottom: 8px;
      left: 0;
    }
  }
}
// 品控中心
.m-dxby-pk {
  img {
    .w(100%)
  }
}
// 高质量产品
.m-dxby-quality {
  margin: 36px 0 20px;
  text-align: center;
  h3 {
    color: #333;
    font-weight: 700;
    font-size: 20px;
    font-family: '方正粗黑宋简体';
    padding-bottom: 5px;
  }
  h5 {
    color: #666;
    .fsize(14px);
  }
}
// 镇店爆品
.m-dxby-bp {
  .m-dxby-bp-list {
    ul {
      min-width: 100%;
      display: flex;
      overflow-x: auto;
      flex-wrap: nowrap;
      padding-bottom: 6px;
      li {
        .w(150px);
        flex-shrink:0;
        .rds(8px);
        overflow: hidden;
        margin-bottom: 5px;
        box-shadow: 0 3px 8px #7892ba;
        overflow: hidden;
        margin-right: 12px;
        .m-dxby-bp-list-img {
          .wh(150px, 350/2px);
          .rds(8px);
          overflow: hidden;
        }
        h3 {
          // .h(20px);
          .fsize(15px);
          font-family: '方正粗黑宋简体';
          color: #333;
          display: block;
          text-align: center;
          margin: 15px 0 8px;
          padding: 0 6px;
          .otx(1);
        }
        span {
          .fsize(12px);
          color: #666;
          display: block;
          text-align: center;
          margin: 0 0 8px;
          font-family: '微软雅黑';
          .otx(2);
          .lht(20px);
          padding: 0 6px;
          flex-wrap: wrap;
        }
        .m-dxby-bp-list-btn {
          margin: 5px 6px 15px;
          button {
            .wh(100%, 30px);
            .lht(30px);
            .bg(#7892ba);
            .fsize(12px);
            .rds(20px);
            color: #fff;
            border: 0;
            outline: none;
          }
        }
      }
      li:last-child {
        margin-right: 0;
      }
    }
  }
}
// 打底保暖
.m-dxby-warmth {
  margin-bottom: 26px;
  ul {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    li {
      .w(48%);
      box-sizing: border-box;
      box-shadow: 0 0 9px rgba(120, 146, 186, .8);
      margin-bottom: 14px;
      .rds(5px);
      padding-bottom: 18px;
      overflow: hidden;
      .m-dxby-warmth-img-box {
        .wh(190, 172px);
        .m-dxby-SUniform-img {
          .wh(100%, 100%);
        }
      }
      span {
        display: block;
        text-align: center;
        color: #333;
        padding: 0 5px;
        .fsize(14px);
        .otx (1);
      }
      span:nth-child(2) {
        margin: 6px 0;
      }
    }
    li:nth-child(2n + 1) {
      margin-left: 3px;
    }
    li:nth-child(2n + 1) {
      margin-right: 3px;
    }
  }
}
// 最小设备
@media screen and (width:320px){
  .m-dxby-quality {
    h5 {
      .fsize(13px)
    }
  }
  .m-dxby-SUniform {
    ul {
      li {
        a {
          .m-dxby-SUniform-img {
            .wh(90px, 90px);
          }
        }
      }
    }
  }
  .m-dxby-warmth {
    ul {
      li {
        margin-bottom: 10px;
        box-shadow: 0 0 9px rgba(120, 146, 186, .7);
      }
    }
  }
}

</style>
