<template>
  <div class="title">
    <section>
      <h3>{{vtitle}}</h3>
      <span v-html="downTitle"></span>
    </section>
    <router-link to="/product" class="more">更多 <b style="font-weight: 500">>></b></router-link>
  </div>
</template>

<script>
export default {
  name: 'pTitle',
  props: {
    vtitle: {
      type: String,
      require: true
    },
    downTitle: {
      type: String,
      require: true
    }
  }
}
</script>

<style scoped lang="less">
.title {
  position: relative;
  text-indent: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &::before {
    width: 4px;
    height: 100%;
    content: '';
    background-color: #f084b1;
    position: absolute;
    top: 0;
    left: 0;
  }
  .more {
    font-size: 14px;
    color: #333;
    display: block;
    font-family: '微软雅黑' !important;
    // background: url(~@/assets/images/index/more.png) no-repeat center right / 10px;
  }
  span {
    width: 210px;
    font-family: '方正粗黑宋简体';
    font-size: 12px;
    display: block;
    letter-spacing: -1px;
    color: #666;
    padding-bottom: 3px;
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  h3 {
    font-family: '方正粗黑宋简体';
    font-size: 18px;
    display: block;
    padding-top: 4px;
    padding-bottom: 7px;
    color: #333;
  }
}
</style>
